<template>
  <div class="listing-receipt">
    <navbar title="房源收件" />

    <div class="listing-box">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">
        <div class="listing-item" v-for="item in listingList" :key="item.id">
          <div class="item-top">
            <div class="item-date">{{getTimes.timestampToTime(item.createTime)}}</div>
<!--            <div class="item-certificate">证书上传{{ item.certificate }}%</div>-->
            <div class="item-state" v-if="item.status == -2">待审核</div>
            <div class="item-state" v-else-if="item.status == -1">待上架</div>
            <div class="item-state01" v-else-if="item.status == 4">申请上架中</div>
            <div class="item-state02" v-else-if="item.status == 0">已上架</div>
            <div class="item-state03" v-else-if="item.status == 1">已下架</div>
            <div class="item-state02" v-else-if="item.status == 2">已成交</div>
          </div>
          <div class="box-item">
            <house-item :item="item" :types="1" :label="false" color="#00d3c4"></house-item>
          </div>
          <div class="item-bottom" v-if="item.status == -2 || item.status == -1">
            <div class="item-btn" @click="goRelease(item.id)">编辑</div>
            <div class="item-btn" @click="approved(item.id)" v-if="item.status == -2">
              审核通过
            </div>
            <div class="item-btn" @click="grounding(item.id)" v-else-if="item.status == -1">
              申请上架
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import houseItem from "../../components/HouseItem.vue";

export default {
  name: "listingReceipt",
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      loading: false,
      finished: false,
      listingList: [],
    };
  },

  mounted() {
    // this.getListingreceipt();
  },
  methods: {
    onLoad() {
      this.loading = true;
      this.pageNum++;
      this.getListingReceipt();
    },

    //   跳转到编辑页面
    goRelease(id) {
      this.$router.push({
        path: "/release",
        query: {
          id: id,
        },
      });
    },
    //审核通过
    approved(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认审核通过？",
        })
        .then(() => {
          console.log(id);
          const data = {
            id: id,
          };
          this.$post("secondHouse/getAudit", data).then((res) => {
            if (res.status == 200) {
              var router = this.$toast.success({
                message: "审核成功",
                duration: 1500,
              });
              location.reload();
            }else {
              this.$toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          console.log(id);
        });
    },
    //申请上架
    grounding(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认申请上架？",
        })
        .then(() => {
          console.log(id);
          const data = {
            id: id,
          };
          this.$post("secondHouse/getAudit", data).then((res) => {
            if (res.status == 200) {
              var router = this.$toast.success({
                message: "审核成功",
                duration: 1500,
              });
              location.reload();
            }else {
              this.$toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          console.log(id);
        });
    },

    getListingReceipt() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$post("secondHouse/getListingReceipt", data).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.finished = false;
          let flag = false;
          for (var i = 0; i < res.data.list.length; i++) {
            console.log("房源收件获取图片" + res.data.list[i].housePic);
          }

          this.listingList = this.listingList.concat(res.data.list);
          if (
            res.data.list.length == res.pageSize &&
            res.data.count % this.pageSize == this.pageNum &&
            res.data.count / this.pageSize == 0
          ) {
            flag = true;
          }
          if (
            this.listingList.length >= res.data.conut ||
            res.data.list.length < this.pageSize ||
            flag
          ) {
            this.finished = true;
          }
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  created() {},
  components: {
    navbar,
    houseItem,
  },
};
</script>
<style lang="less" scoped>
.listing-receipt {
  .listing-box {
    padding-bottom: 20px;
    .listing-item {
      margin-bottom: 10px;
      .item-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        line-height: 36px;
        font-size: 13px;
        background: #fbfbfb;
        .item-date {
          color: #858585;
        }

        .item-certificate {
          color: #f00;
        }
        .item-state {
          color: #f00;
        }
        .item-state01 {
          color: #fcc707;
        }
        .item-state02 {
          color: #00d3c4;
        }
        .item-state03 {
          color: #cccc;
        }
      }
      .box-item {
        div {
          line-height: 1;
        }
        padding: 10px 12px;
        border-bottom: 1px solid #eee;
        background-color: #fff;
        &:last-child {
          border-bottom: 0;
        }
      }
      .item-bottom {
        background-color: #fff;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .item-btn {
          color: #555;
          font-size: 12px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #999;
          border-radius: 14px;
          width: 80px;
        }
      }
    }
  }
}
</style>